<template>
    <div class="need-handle">
        <Topbar title="我的待办">
            <template slot="menu">
                <div class="menu">
                    <div :class="{ 'active': activeMenu === item }" class="menu-item" v-for="(item, index) in menuList"
                        :key="index" @click="menuClick(item)">{{ item.label }}</div>
                </div>
            </template>
            <template slot="right">
                <i class="back iconfont icon-shanchu" @click="back"></i>
            </template>
        </Topbar>
        <div class="body">
            <div class="content">
                <div class="search-container">
                    <Search :params="params" :paramsHeaders="paramsHeaders" :getTableData="getTableData"
                        ignoreKeys="isSelf,isDeal,isSelfSubmit" >
                        <template slot="startTime">
                            <el-date-picker size="small" v-model="startTimeValue" @change="startTimeValueChange"
                                type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" clearable>
                            </el-date-picker>

                        </template>
                    </Search>
                </div>

                <div class="table-container">
                    <CommonTable :headers="headers" :tableData="tableData" :selection="hasSelection"
                        :selectList.sync="selectList" :loading="loading" :hasIndex="hasIndex" :maxHeight="700">
                        <template slot="formType" slot-scope="{ row }">
                            <!-- {{row.formType}} -->
                            {{ EnumType.applyStatus[row.formType] }}
                        </template>

                        <template slot="examineStatus" slot-scope="{ row }">
                            <div :style="{ 'color': EnumType.examineStatusColor[row.examineStatus] }">
                                {{ EnumType.examineStatus[row.examineStatus] }}</div>
                        </template>
                        <template slot="examineResult" slot-scope="{ row }">
                            <div :style="{ 'color': EnumType.examineResultColor[row.examineResult] }">
                                {{ EnumType.examineResult[row.examineResult] }}</div>
                        </template>
                        <template slot="operate" slot-scope="{ row }">
                            <div class="operate">
                                <el-button style="color:#008B61;" v-if="activeMenu && activeMenu.value === 'isSelf'" v-permission="['/hrms/user/change/examine-flow']"
                                    type="text" size="small" @click="handleClick(true, row)">通过</el-button>
                                <el-button style="color:#FF2525;" v-if="activeMenu && activeMenu.value === 'isSelf'" v-permission="['/hrms/user/change/examine-flow']"
                                    type="text" size="small" @click="handleClick(false, row)">拒绝</el-button>
                                <el-button type="text" size="small" @click="detailClick(row)">查看</el-button>
                                <el-button v-if="hasReSubmit(row)" type="text" size="small"
                                    @click="reSubmit(row)">重提</el-button>
                            </div>
                        </template>
                    </CommonTable>
                    <div class="content-footer">
                        <div>
                            <el-button v-if="activeMenu && activeMenu.value === 'isSelf'" size="small" type="danger" v-permission="['/hrms/user/change/examine-flow']"
                                @click="handleClick(false)" :disabled="selectList.length === 0">拒绝</el-button>
                            <el-button v-if="activeMenu && activeMenu.value === 'isSelf'" size="small" type="success" v-permission="['/hrms/user/change/examine-flow']"
                                @click="handleClick(true)" :disabled="selectList.length === 0">通过</el-button>
                        </div>
                        <div class="pagination">
                            <el-pagination background layout="total,sizes,prev, pager, next"
                                :current-page.sync="params.current" :page-sizes="[10, 30, 50, 100]"
                                :page-size.sync="params.size" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="openDetail" width="80%" v-if="detailInfo" :close-on-click-modal="false"  custom-class="el">
            <template slot="title">
                <FlowHeader :detailInfo="detailInfo"></FlowHeader>
            </template>
            <div class="detail-container" >
                <Detail :detailInfo="detailInfo.extra.formData"
                    v-if="detailInfo.type === 'REGISTER' || detailInfo.type === 'CHANGE'"></Detail>

                <Transfer v-if="detailInfo.type === 'ADJUST_POST'" :title="detailInfo.name"
                    :form="detailInfo.extra.formData" :detailInfo="detailInfo"></Transfer>

                <Leave v-if="detailInfo.type === 'LEAVE'" :title="detailInfo.name" :form="detailInfo.extra.formData"
                    :detailInfo="detailInfo"></Leave>

                <Examine class="mt15" :tableData="detailInfo.historyWorkFlows"></Examine>

                <el-input v-if="activeMenu && activeMenu.value === 'isSelf'" class="mt15" v-model="msg" type="textarea" :rows="3" resize="none"
                    placeholder="请填写审批意见"></el-input>
            </div>
            <div slot="footer">
                <el-button size="small" @click="openDetail = false">关闭</el-button>
                <el-button v-if="activeMenu && activeMenu.value === 'isSelf'" size="small" type="danger"
                    @click="save(false)">拒绝</el-button>
                <el-button v-if="activeMenu && activeMenu.value === 'isSelf'" size="small" type="success"
                    @click="save(true)">通过</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import Topbar from '@/components/topbar';
import { flowUserList, examineFlow, getFormData } from '@/api/hrAPI.js';
import Menu from '@/components/menu';
import Detail from '@/components/detail';
import Transfer from '@/components/table/transfer';
import Leave from '@/components/table/leave';
import Examine from '@/components/table/examine';

export default {
    components: {
        Topbar,
        Menu,
        Detail,
        Transfer,
        Leave,
        Examine,
    },
    data () {
        return {
            menuList: [
                {
                    label: '待我处理',
                    value: 'isSelf'
                },
                {
                    label: '已处理',
                    value: 'isDeal'
                },
                {
                    label: '我提交的',
                    value: 'isSelfSubmit'
                },
            ],
            activeMenu: null,
            params: {
                current: 1,
                size: 30,
                startTime: '',
                endTime: '',
                type: '',
                examineStatus: '',
                userName: '',
                isSelf: true,       //  代表待办的信息，是否本人待办
                isDeal: '',
                isSelfSubmit: '',
            },
            headers: [
                {
                    label: '审批类型',
                    value: 'formType',
                    slot: 'formType'
                },
                {
                    label: '干部姓名',
                    value: 'name',
                },
                {
                    label: '审批状态',
                    value: 'examineStatus',
                    slot: 'examineStatus'
                },
                {
                    label: '创建人',
                    value: 'creatorName',
                },
                {
                    label: '创建时间',
                    value: 'createTime',
                },
                {
                    label: '操作',
                    slot: 'operate',
                },
            ],
            tableData: [],
            selectList: [],
            loading: false,
            total: 0,
            openDetail: true,
            detailInfo: null,
            msg: '',
            startTimeValue: [],
            hasSelection: false,
            hasIndex: true      
		}
    },
    computed: {
        paramsHeaders(){
            const headers = [
                {
                    label: '创建时间',
                    slot: 'startTime'
                },
                {
                    label: '审批类型',
                    value: 'type',
                    type: 'select',
                    typeList: this.EnumList.applyStatusList
                },
                {
                    label: '审批状态',
                    value: 'examineStatus',
                    type: 'select',
                    typeList: this.EnumList.examineStatusList
                },
                
                {
                    label: '干部姓名',
                    value: 'userName'
                },
            ]
            if(this.activeMenu && this.activeMenu.value !== 'isSelf'){
                headers.splice(3,0,{
                    label: '审批结果',
                    value: 'examineResult',
                    type: 'select',
                    typeList: [
                        {
                            label: "通过",
                            value: 1
                        },
                        {
                            label: "拒绝",
                            value: 2
                        },
                    ],
                })

            }
            return headers;
        }
    },
    created () {
        this.menuClick(this.menuList[0]);
    },
    watch: {
        'params.startTime': {
            handler (val) {
                if (!val) {
                    this.startTimeValue = [];
                }
            }
        }
    },
    methods: {
        hasReSubmit(row){
            return this.params.isSelfSubmit && row.examineResult === 2 && (row.formType === 'REGISTER' || row.userId);
        },
        menuClick (data) {
            this.activeMenu = data;
            this.params.isSelf = false;
            this.params.isDeal = false;
            this.params.isSelfSubmit = false;
            this.params.userName= '';
            this.params.startTime = '';
            this.params.endTime = '';
            this.params.type = '';
            this.params.examineStatus = '';
			this.hasSelection = data.value === 'isSelf'
            switch (data.value) {
                case 'isSelf':
                    this.params.isSelf = true;
                    // this.hasSelection = true;
                    // this.hasIndex=true;
                    break;
                case 'isDeal':
                    this.params.isDeal = true;
                    if (!this.headers.find(header => header.value === 'examineResult')) {
                        this.headers.splice(3, 0, {
                            label: '审批结果',
                            value: 'examineResult',
                            slot: 'examineResult',
                        })
                    }
                    // this.hasSelection = true;
                    // this.hasIndex=true;
                    break;
                case 'isSelfSubmit':
                    this.params.isSelfSubmit = true;
                    if (!this.headers.find(header => header.value === 'examineResult')) {
                        this.headers.splice(3, 0, {
                            label: '审批结果',
                            value: 'examineResult',
                            slot: 'examineResult'
                        })
                    }
                    this.hasSelection = false;
                    // this.hasIndex=true;
                    break;
            }
            // this.getTableData();
           this.$nextTick(() =>  this.getTableData())
        },
        getTableData () {
            this.loading = true;
            flowUserList(this.params).then(res => {
                this.tableData = res.records;
                this.total = res.total;
            }).finally(() => {
                this.loading = false;
            })
        },
        handleClick (flag, row) {
            let selectList = this.selectList;
            let tag = '';
            let title = '';
            if (row) {
                selectList = [row];
                tag = <p>确认审核<span style={{ color: flag ? '#008B61' : '#FF7272', margin: '0 5px' }}>{flag ? '通过' : '拒绝'}</span>吗？</p>;
                title = '单个审核';
            } else {
                tag = <p>
                    这<span style="color:#FF7272;margin:0 5px;">{selectList.length}</span>条数据，
                    确认审核
                    <span style={{ color: flag ? '#008B61' : '#FF7272', margin: '0 5px' }}>{flag ? '通过' : '拒绝'}</span>
                    吗？
                </p>
                title = '批量审核';
            }
            this.$prompt(tag, title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: flag ? '' : /\S/,
                inputErrorMessage: '审批意见不能为空',
                dangerouslyUseHTMLString: true,
                inputPlaceholder: flag ? '选填审批意见' : '拒绝时，必须填写审批意见',
                closeOnClickModal: false,
            }).then(({ value }) => {
                this.handleExamine(selectList, flag, value);
            }).catch(err => {
                console.log(err);
            })
        },
        save (flag) {
            const params = [
                {
                    approved: flag,
                    opinion: this.msg,
                    taskId: this.detailInfo.taskId
                }
            ]
            if (!flag) {
                if (!this.msg) {
                    this.$message.error('拒绝时，必须填写审批意见');
                    return;
                }
            }
			const { name } = this.detailInfo.extra.formData || ''
			const path = '/hrms/user/change/examine-flow'
			const note= `${name}审批${flag ?  '通过' : '拒绝'}`
		  	this.$store.commit('status/SET_LOG', {path, note})
            examineFlow(params).then(res => {
                // console.log(res,'123');
				this.msg = ''
                this.$message.success('操作成功');
                this.getTableData();

                this.openDetail = false;
            })
        },
        handleExamine1 (selectList, flag, msg) {
            const params = selectList.map(item => {
                return {
                    approved: flag,
                    opinion: msg,
                    taskId: item.taskId
                }
            })
			const path = '/hrms/user/change/examine-flow'
			const names = selectList.map(item => item.name)
			const note= `${names.join(',')}审批${flag ? '通过' : '拒绝'}`
		  	this.$store.commit('status/SET_LOG', {path, note})
            examineFlow(params).then(res => {
                this.$message.success('操作成功');
                this.getTableData();
            })
        },
		async handleExamine(selectList, flag, msg) {
			for (const task of selectList) {
				const { taskId, name } = task
				const path = '/hrms/user/change/examine-flow'
				const note= `${name}审批${flag ? '通过' : '拒绝'}`
		  		this.$store.commit('status/SET_LOG', {path, note})
				const param = {
                    approved: flag,
                    opinion: msg,
                    taskId: taskId
                }
				await examineFlow([param])
			}
			this.$message.success('操作成功');
            this.getTableData();
        },
        detailClick (row) {
            getFormData({ id: row.id }).then(res => {
                this.detailInfo = res;
                this.openDetail = true;
            })
        },
        back () {
            this.$router.back();
        },
        reSubmit (row) {
            let routeUrl = '';
            let addPath = '';
            if(this.$route.path.endsWith('/cadre')){
                addPath = '/cadre';
            }
            if(this.$route.path.endsWith('/dean')){
                addPath = '/dean';
            }
            if(row.formType === 'REGISTER'){
                // REGISTER
                // CHANGE
                // LEAVE
                // ADJUST_POST
                routeUrl = this.$router.resolve({
                    path: `/register${addPath}`,
                    query: {
                        id: row.id,
                    }
                })
            }else{
                routeUrl = this.$router.resolve({
                    path: `/user-detail${addPath}`,
                    query: {
                        userId: row.userId,
                        flowId: row.id,
                        formType: row.formType
                    }
                })
            }

            window.open(routeUrl.href, '_blank');

        },
        startTimeValueChange (val) {
            if(val){
                this.params.startTime = val[0];
                this.params.endTime = val[1];
            }else{
                this.params.startTime = '';
                this.params.endTime = '';
            }
        },
    },
}
</script>

<style lang="less" scoped>
// .el {
//     .el-dialog {
//         .el-dialog__body {
//             padding-top: 10px!important
//         }
//     }
// }
.need-handle {
    width: 100vw;
    height: 100vh;
    ;
    background-color: #EFF3F7;

    .back {
        cursor: pointer;
        padding: 5px;
        float: right;
    }

    .menu {
        display: flex;
        align-items: center;

        .menu-item {
            width: 100px;
            height: 32px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #CCD2E0;
            background-color: #F5F5F5;
            border-radius: 4px;
            margin-right: 12px;
            box-sizing: border-box;
            cursor: pointer;
        }

        .menu-item.active {
            border-color: #008B61;
            color: #008B61;
            background-color: #fff;
        }
    }

    .body {
        width: 100%;
        height: calc(100% - 50px);
        box-sizing: border-box;
        padding: 10px 20px 0;

        .content {
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            background-color: #fff;
            position: relative;

            .search-container {
                display: flex;
                font-size: 14px;

                .search-item {
                    margin-right: 20px;
                    display: flex;
                    align-items: center;

                    .label {
                        margin-right: 10px;
                        color: #101010;
                    }

                    .content {}
                }
            }

            .table-container {
                margin-top: 16px;
            }

            .content-footer {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 40px;
                width: calc(100% - 40px);
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.detail-container {
    height: 540px;
    overflow: auto;
}</style>