<template>
    <div class="topbar">
        <div class="left">
            <div class="back" v-if="hasBack" @click="back">
                <i class="el-icon-arrow-left"></i>
				<span class="text">返回</span>
            </div>
            <slot name="title" class="title" :style="titleStyle">
                <div>
                    {{title}}
                </div>
            </slot>
        </div>
        <div class="menu-container">
            <slot name="menu"></slot>
        </div>
        <div class="right-container">
            <slot name="right">
            </slot>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            default: '顶部栏标题'
        },
        titleStyle: {
            type: Object,
            default(){
                return {};
            }
        },
        hasBack: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            
        }
    },
    methods: {
        back(){
            this.$emit('back');
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.topbar{
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #fff;
    .left{
        display: flex;
        align-items: center;
        .back{
            min-width: 50px;
            height: 30px;
            border-radius: 30px;
            border: 1px solid #101010;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
			padding: 0 10px;
			
			.text {
				width: 30px;
				margin-left: 5px;
			}
        }
        .title{
            font-size: 16px;
        }
    }
    .menu-container{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .right-container{
        display: flex;
        justify-content: flex-end;
    }
    > div{
        min-width: 30%;
        white-space: nowrap;
    }
}
</style>